body {
  background-color: #282c34;
  color: white;
}

.content {
  display: flex;
  border-top: 1px solid white;
  min-height: 10vh;
}

.content-flex {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.content-title {	
  font-size: calc(10px + 2vmin);
}

.content-paragraph {
}

.App-header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content-link {
  color: white;
}